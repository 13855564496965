/* JS specific to incident scanning, and system generated events in Allocations etc */

/* globals searchArrayForObject, getSessionUser, showAlert, clearAuthorisation, getProgramTransactionCode */

/**
 * @typedef {Object} incidentGroup
 * @property {Number} id
 * @property {String} description
 * @property {incident[]} incidents
 */

/**
 * @typedef {Object} incident
 * @property {Boolean} casualty - True when used for manual incidents. False if system incident.
 * @property {String} description - The name of the incident
 * @property {Number} incidentCode - Unique incident code
 * @property {String} name - Hardcoded system name for system only incidents
 * @property {Boolean} restricted - If true and the incident is raised, then an admin user must authorize
 * @property {String} restrictionExclusions - A comma list of transaction codes that can be excluded from restrictions.
 * @property {String} type - Type of barcode for manual entry, either CASK LOCATION or both comma delimited.
 * @property {incidentReason[]} incidentReasons - Reasons to choose from when manually raising incident
 */

/**
 * @typedef {Object} incidentReason
 * @property {Number} id - Unique reason ID
 * @property {String} description - Reason for incident
 */

/**
 * @description Save new incident groups to LS for use within the application
 * @param {Array} incidentGroups Either incident groups or [] if none were downloaded.
 */
function saveIncidentsToStorage(incidentGroups) {
	"use strict";
	localStorage.setObject("data/incidentCodes", incidentGroups);
}

function getIncidentGroupsFromStorage() {
	"use strict";
	return localStorage.getObject("data/incidentCodes");
}

/**
 * @description Filters out incidents based on the type, returning only groups with incidents that match.
 * @param {String} type - The barcode type for manual entry, Either CASK or LOCATION.
 * @returns {incidentGroup[]}
 */
function getManualIncidentGroups(type) {
	"use strict";

	var manualIncidentGroups = [],
		storedIncidentGroups = getIncidentGroupsFromStorage(),
		filterFunction = function(el) {
			return el.type.toLowerCase().split(",").indexOf(type.toLowerCase()) >= 0 && el.casualty === true;
		};

	for (var i=0; i<storedIncidentGroups.length; i++) {
		// check each groups incidents. Filter the incidents by the type.
		// If the filtered incidents have at least one then add the resulting group to the return.
		var incidentsForGroupAndType = storedIncidentGroups[i].incidents.filter(filterFunction);
		if (incidentsForGroupAndType.length > 0) {
			manualIncidentGroups.push({
				id: storedIncidentGroups[i].id,
				description: storedIncidentGroups[i].description,
				incidents: incidentsForGroupAndType
			});
		}
	}

	return manualIncidentGroups;
}

/**
 * @description Finds an incident by matching the numeric incidentCode
 * @param {Number} incidentId - The code to search for.
 * @returns {incident | null}
 */
function getIncidentById(incidentId) {
	"use strict";
	var storedIncidentGroups = getIncidentGroupsFromStorage();

	// Find an incident where the incident code matches and return.
	for (var i=0; i<storedIncidentGroups.length; i++) {
    for (var j=0; j<storedIncidentGroups[i].incidents.length; j++) {
			if (storedIncidentGroups[i].incidents[j].incidentCode === incidentId) {
				return storedIncidentGroups[i].incidents[j];
			}
		}
	}
	return null; // Not found a match.
}

/**
 * @description Get the reason codes for the relevant Incident code.
 * @param incidentId
 * @returns {*}
 */
function getReasonsForIncident(incidentId) {
	"use strict";
	var storedIncidentGroups = getIncidentGroupsFromStorage();

	// Find an incident where the incident code matches and return.
	for (var i=0; i<storedIncidentGroups.length; i++) {
		for (var j=0; j<storedIncidentGroups[i].incidents.length; j++) {
			if (storedIncidentGroups[i].incidents[j].incidentCode === parseInt(incidentId)) {
				if (storedIncidentGroups[i].incidents[j].hasOwnProperty("incidentReasons")) {
					return storedIncidentGroups[i].incidents[j].incidentReasons;
				} else {
					return [];
				}
			}
		}
	}
	return []; // Not found a match.
}

/**
 * @description Gets all incident codes that can be raised by the system
 * @returns {incidents[]}
 */
function getAllSystemIncidents() {
	"use strict";
	var systemIncidents = [],
		storedIncidentGroups = getIncidentGroupsFromStorage(),
		  filterFunction = function(el) {
				return el.casualty === false;
			};

	for (var i=0; i<storedIncidentGroups.length; i++) {
		// check each groups incidents. Filter the incidents by the type.
		// If the filtered incidents have at least one then add the resulting group to the return.
		var incidentsForGroupAndType = storedIncidentGroups[i].incidents.filter(filterFunction);
		if (incidentsForGroupAndType.length > 0) {
			systemIncidents = systemIncidents.concat(incidentsForGroupAndType);
		}
	}

	return systemIncidents;
}

/**
 * @description Creates a system incident, to be send in a barcode transaction. Also alerts to the user.
 * @param {String} errorName the barcode incident name / primary code.
 * @param {Boolean} stopProgram If the program should stop on this error.
 * @param {String} data Replacement data for an incident code with {DATA} in its description text.
 * @returns {{code: (*|Number|number|string), text: String, name: String, stopProgram: Boolean}}
 */
function raiseSystemIncident(errorName, stopProgram, data) {
	'use strict';
	data = data || "";
	var restricted = false;
	var incidentCodes = getAllSystemIncidents();
	var thisIncident = incidentCodes[searchArrayForObject(incidentCodes,"name",errorName)];
	if(thisIncident.restricted) {
		// Check to see the transaction code in use is not excluded from this.
		// If the code is not in the exclusions list then we must raise restrictions.
		if (thisIncident.restrictionExclusions.split(",").indexOf(getProgramTransactionCode()) === -1) {
			restricted = true;
			localStorage.setObject("runtime/authorisation",{incident:thisIncident.incidentCode,
				incidentUser:getSessionUser()});
		}

	}
	// use the replace function on the string to replace any data placeholder that can be held in the description.
	// usually this can be empty but is useful for belowLegalAge and belowBlendAge.
	// For double scans it means we can change what we say to the user.
	showAlert(thisIncident.description.replace("{DATA}", data),{auth:restricted,
		authUser:getSessionUser(),
		approvalRequired:(localStorage.getVariable("settings/useIncidentApproval") === "true"),
		focus:"scanInput",callback:function(passed,username) {
			if(passed) {
				clearAuthorisation(username);
			}
		}});
	return {code: thisIncident.incidentCode,
		text: thisIncident.description.replace("{DATA}", data),
		name:thisIncident.name,
		stopProgram:stopProgram};
}